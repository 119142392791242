import HttpClient from "../utils/HttpClient";
import {
  type District,
  type Province,
  type School,
} from "@/api/prisma-interfaces";
const SCHOOLS_URL = "4/schools";

export interface SchoolDto
  extends Omit<
    School,
    | "id"
    | "oldId"
    | "createdAt"
    | "updatedAt"
    | "Location"
    | "bulletinId"
    | "District"
    | "Users"
    | "Menu"
    | "feedbackAllowed"
  > {
  id?: School["id"];
}

class SchoolService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async getSchools(
    take?: number,
    skip?: number,
  ): Promise<{ schools: School[]; total: number }> {
    const response = await this.httpClient.get<{
      schools: School[];
      total: number;
    }>(
      `/admin/${SCHOOLS_URL}?take=${take ? take : "20"}${
        skip ? `&skip=${skip}` : ""
      }`,
    );
    return response;
  }

  async getSchoolsNearby(
    latitude: number,
    longitude: number,
  ): Promise<School[]> {
    const response = await this.httpClient.get<{ schools: School[] }>(
      `/3/schools/nearby?latitude=${latitude}&longitude=${longitude}`,
    );
    return response.schools;
  }

  async getSchoolsByDistrictId(districtId: string): Promise<School[]> {
    const response = await this.httpClient.get<School[]>(
      `/admin/${SCHOOLS_URL}/district/${districtId}`,
    );
    return response;
  }

  async getSchoolsByDistricts(districtIds: string[]): Promise<School[]> {
    const response = await this.httpClient.get<School[]>(
      `/admin/${SCHOOLS_URL}/districts?ids=${districtIds}`,
    );
    return response;
  }

  async getSchoolById(id: string): Promise<School> {
    const response = await this.httpClient.get<School>(
      `/admin/${SCHOOLS_URL}/${id}`,
    );
    return response;
  }

  async getVisibleSchoolsByDistrictId(districtId: string): Promise<{
    schools: School[];
    district: District;
    province: Pick<Province, "id" | "name">;
  }> {
    const response = await this.httpClient.get<{
      schools: School[];
      district: District;
      province: Pick<Province, "id" | "name">;
    }>(`/${SCHOOLS_URL}?district=${districtId}`);
    return response;
  }

  async getVisibleSchoolsByDistrictUrlName(districtUrlName: string): Promise<{
    schools: School[];
    district: District;
    province: Pick<Province, "id" | "name" | "urlName" | "urlNames">;
  }> {
    const response = await this.httpClient.get<{
      schools: School[];
      district: District;
      province: Pick<Province, "id" | "name" | "urlName" | "urlNames">;
    }>(`/${SCHOOLS_URL}/${districtUrlName}`);
    return response;
  }
  async createSchool(school: SchoolDto): Promise<School> {
    const response = await this.httpClient.post<School>(
      `/admin/${SCHOOLS_URL}`,
      school,
    );
    return response;
  }

  async updateSchool(school: SchoolDto): Promise<School> {
    const response = await this.httpClient.put<School>(
      `/admin/${SCHOOLS_URL}`,
      {
        id: school.id,
        name: school.name,
        visible: school.visible,
        districtId: school.districtId,
        menuId: school.menuId,
        customerId: school.customerId,
        istSchoolId: school.istSchoolId,
        istSchoolIds: school.istSchoolIds,
      },
    );
    return response;
  }

  async updateSchoolImage(schoolId: string, image: FormData): Promise<School> {
    return await this.httpClient.put<School>(
      `/admin/${SCHOOLS_URL}/${schoolId}/image`,
      image,
    );
  }

  async updateSchoolLocation(
    schoolId: string,
    latitude: number,
    longitude: number,
  ): Promise<School> {
    return await this.httpClient.put<School>(
      `/admin/${SCHOOLS_URL}/${schoolId}/location`,
      {
        latitude,
        longitude,
      },
    );
  }

  async deleteSchool(id: string): Promise<void> {
    await this.httpClient.delete(`/admin/${SCHOOLS_URL}/${id}`);
  }
}

export default new SchoolService();
