import OrganisationService from "@/services/OrganisationService";
import { getErrorMessage } from "@/utils/ErrorHandler";
import { acceptHMRUpdate, defineStore } from "pinia";

export interface OrganisationState {
  istSchools: { id: string; name: string; schoolCode: string }[];
  loading: { [key: string]: boolean };
  error?: { [key: string]: any } | null;
}

export const FETCH_IST_SCHOOLS = "FETCH_IST_SCHOOLS";
export const IST_SCHOOLS = "IST_SCHOOLS";

export const useOrganisationStore = defineStore("organisation", {
  state: (): OrganisationState => ({
    istSchools: [],
    loading: {},
    error: null,
  }),
  actions: {
    async [FETCH_IST_SCHOOLS](customerId: string, query?: string) {
      this.setLoading(FETCH_IST_SCHOOLS, true);
      try {
        if (this.istSchools.length > 0) {
          if (query) {
            return this.istSchools.filter((s) =>
              s.name.toLowerCase().includes(query.toLowerCase()),
            );
          }
          return this.istSchools;
        } else {
          const res = await OrganisationService.getISTSchools(
            customerId,
            query,
          );
          this.istSchools = res.schools;
          return res.schools;
        }
      } catch (error) {
        this.setError(FETCH_IST_SCHOOLS, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_IST_SCHOOLS, false);
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        throw value;
      }
    },
  },
  getters: {
    [IST_SCHOOLS]: (state: OrganisationState) => state.istSchools,
    isLoading: (state: OrganisationState) => (action: string) =>
      state.loading[action],
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useOrganisationStore, import.meta.hot),
  );
}
