<script setup lang="ts">
import { reactive, onMounted, computed, ref, watch, provide } from "vue";
import DistrictDetail from "./DistrictDetail.vue";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import Dialog from "primevue/dialog";
import DistrictForm from "./DistrictForm.vue";
import Column from "primevue/column";
import Button from "primevue/button";

import { useDistrictStore } from "@/stores/DistrictStore";
import { useRoute, useRouter } from "vue-router";
import type { DistrictDto } from "@/services/DistrictService";
import { useProvinceStore } from "@/stores/ProvinceStore";
import { useMenuStore } from "@/stores/MenuStore";
import SearchField from "@/components/shared/SearchField.vue";
import { Role, type District, type Province } from "@/api/prisma-interfaces";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useUserStore } from "@/stores/UserStore";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const { useSuccessToast, useErrorToast } = useFeedbackToast();

const districtStore = useDistrictStore();
const provinceStore = useProvinceStore();
const menuStore = useMenuStore();
const userStore = useUserStore();

onMounted(async () => {
  await districtStore.FETCH_DISTRICTS();
  districts.value = districtStore.DISTRICTS;
  if (route.params.id) {
    SELECTED_DISTRICT.value =
      (await districtStore.GET_DISTRICT(route.params.id as string)) ||
      undefined;
  } else {
    SELECTED_DISTRICT.value = undefined;
  }
  provinceStore.FETCH_PROVINCES();
  menuStore.FETCH_MENUS();
});

const state = reactive({
  showForm: false,
});

const LOADING_DISTRICTS = computed(() => {
  return districtStore.$state.loading.FETCH_DISTRICTS;
});

const SELECTED_PROVINCE = ref<Province>();
const SELECTED_DISTRICT = ref<District>();
const usePaginator = ref(true);

watch(
  () => route.params.id as string,
  async (id) => {
    if (route.name !== "DistrictDetail") return;
    if (id) {
      SELECTED_DISTRICT.value =
        (await districtStore.GET_DISTRICT(id)) || undefined;
    } else {
      SELECTED_DISTRICT.value = undefined;
    }
  },
);

watch(SELECTED_PROVINCE, async (newValue) => {
  if (newValue?.id) {
    usePaginator.value = false;
    const newDistricts = await districtStore.GET_DISTRICTS_BY_PROVINCE_ID(
      newValue.id,
    );
    if (newDistricts) {
      districts.value = newDistricts.districts;
    }
  } else {
    usePaginator.value = true;
    districts.value = districtStore.DISTRICTS;
  }
});

function toggleModal() {
  state.showForm = !state.showForm;
}

const PROVINCES = computed(() =>
  provinceStore.provinces.map((province) => ({
    id: province.id.toString(),
    value: province.name,
  })),
);

const DISTRICTS = computed(() => {
  return districtStore.DISTRICTS;
});

const NEW_DISTRICT = reactive<DistrictDto>({
  name: "",
  imageUrl: null,
  provinceId: "",
  menuId: null,
  image: null,
  bulletinId: null,
  customerId: null,
});

const resetNewDistrict = () => {
  Object.assign(NEW_DISTRICT, {
    name: "",
    imageUrl: null,
    provinceId: "",
    menuId: null,
    image: null,
    bulletinId: null,
    customerId: null,
  });
};

const CREATE_DISTRICT = async () => {
  const trimmedName = NEW_DISTRICT.name.trim();
  const trimmedCustomerId = NEW_DISTRICT.customerId?.trim();
  if (!trimmedName || !NEW_DISTRICT.provinceId) {
    const detail = [];
    if (!trimmedName) {
      detail.push(t("name"));
    }
    if (!NEW_DISTRICT.provinceId) {
      detail.push(t("province"));
    }
    useErrorToast(
      t("create_", { item: t("district") }),
      t("empty_field_", { item: detail.join(", ") }),
    );
    return;
  }
  try {
    const d = await districtStore.CREATE_DISTRICT({
      ...NEW_DISTRICT,
      name: trimmedName,
      customerId: trimmedCustomerId || null,
    });
    if (d) {
      toggleModal();
      resetNewDistrict();
      useSuccessToast(t("create_", { item: t("district") }));
      router.push(`/admin/district/${d?.id}`);
    }
  } catch (error) {
    useErrorToast(t("create_", { item: t("district") }));
  }
};

const first = ref(0);
const districts = ref(DISTRICTS.value);

const onPage = async (event: { first: number; rows: number }) => {
  await districtStore.FETCH_DISTRICTS(event.rows, event.first);
  first.value = event.first;
  districts.value = districtStore.DISTRICTS;
};

const CREATE_DISTRICT_LOADING = computed(
  () => districtStore.$state.loading.CREATE_DISTRICT,
);

const resetSelectedDistrict = () => (SELECTED_DISTRICT.value = undefined);
provide("resetSelectedDistrict", resetSelectedDistrict);
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row flex-wrap items-end justify-between gap-2 rounded-t-lg border border-slate-200 bg-gray-50 p-5"
    >
      <div class="flex flex-col gap-2 sm:flex-row">
        <div class="flex flex-col">
          <span class="pl-2 text-sm font-semibold">{{ t("district") }}</span>
          <search-field
            item-type="district"
            class="rounded-md border border-surface-300"
          />
        </div>
        <Divider class="hidden sm:inline-block" layout="vertical" />
        <div class="flex flex-col">
          <span class="pl-2 text-sm font-semibold">{{
            $t("province", 2)
          }}</span>
          <Dropdown
            v-model="SELECTED_PROVINCE"
            :options="PROVINCES"
            option-label="value"
            filter
            :placeholder="t('select_', { item: t('province') })"
            show-clear
            :empty-message="t('no_available_options')"
            :empty-filter-message="t('no_results_found')"
          />
        </div>
      </div>
      <div class="flex justify-end">
        <div class="flex items-end">
          <Button
            v-if="userStore.CURRENT_USER?.role === Role.superAdmin"
            class="flex justify-center font-semibold"
            @click="state.showForm = true"
          >
            <div class="flex justify-center">
              <span>{{ t("new_district") }}</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row">
    <div class="w-1/3 rounded-bl-lg border border-t-0 border-slate-200">
      <data-table
        :value="districts"
        lazy
        data-key="id"
        :paginator="usePaginator"
        paginator-template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        scrollable
        :scroll-height="'calc(100vh - 220px)'"
        :loading="LOADING_DISTRICTS"
        :selection="SELECTED_DISTRICT"
        :rows="usePaginator ? 20 : undefined"
        :first="usePaginator ? first : 0"
        :total-records="districtStore.count"
        :row-class="
          ({ id }) => [
            'cursor-pointer',
            'hover:bg-gray-100',
            id === SELECTED_DISTRICT?.id
              ? ' border-l-[3px] border-l-slate-800 flex flex-col bg-gray-50'
              : '',
          ]
        "
        :row-hover="true"
        @page="onPage($event)"
        @row-click="(e) => $router.push(`/admin/district/${e.data.id}`)"
      >
        <column
          field="name"
          :header="t('district', 2)"
          :header-style="{ fontWeight: 'bold', paddingTop: '18px' }"
          class="px-6"
        />
      </data-table>
    </div>

    <div
      class="flex w-full rounded-br-lg border border-l-0 border-t-0 border-slate-200"
    >
      <district-detail
        v-if="!!SELECTED_DISTRICT"
        :district="{ ...SELECTED_DISTRICT }"
      />
      <div v-else class="mt-5 flex h-full w-full justify-center">
        <span class="text-gray-500">{{ t("no_district_selected") }}</span>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="state.showForm" modal closable dismissable-mask>
    <template #header>
      <h1 class="font-bold text-slate-800">
        {{ t("new_district") }}
      </h1></template
    >
    <div>
      <span class="block text-sm text-slate-600">{{ t("fill_in_form") }}</span>
      <district-form
        :district="NEW_DISTRICT"
        class="mt-5"
        @update:name="NEW_DISTRICT.name = $event"
        @update:selected-menu-id="NEW_DISTRICT.menuId = $event"
        @update:selected-province-id="NEW_DISTRICT.provinceId = $event"
        @update:customer-id="NEW_DISTRICT.customerId = $event"
      />

      <div class="mt-5 flex justify-end">
        <Button
          class="mr-5 flex justify-center"
          severity="secondary"
          outlined
          @click="toggleModal"
        >
          <div class="flex justify-center font-semibold">
            <span>{{ t("cancel") }}</span>
          </div>
        </Button>

        <Button
          class="flex justify-center"
          :loading="CREATE_DISTRICT_LOADING"
          @click="CREATE_DISTRICT"
        >
          <div class="flex justify-center font-semibold">
            <span>{{ t("save") }}</span>
          </div>
        </Button>
      </div>
    </div>
  </Dialog>
</template>
